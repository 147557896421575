/**
 * Generates random particles using canvas
 *
 * @class Particles
 * @constructor
 * @param {Element} el
 */
export default class Particles {
  constructor(el) {
    this.colors = [
      '120, 114, 255',
      '255, 200, 44',
      '255, 120, 73',
      '254, 67, 103',
      '255, 73, 219'
    ]

    this.minRadius = 4
    this.maxRadius = 8
    this.numParticles = 10
    this.canvas = el
    this.ctx = this.canvas.getContext('2d')
    this.dpr = window.devicePixelRatio
    this.render()
    this._addHooks()
  }

  _rand(min, max) {
    return Math.random() * (max - min) + min
  }

  _addHooks() {
    const self = this
    window.addEventListener('resize', function () {
       window.requestAnimationFrame(function () {
        self.render()
      })
    })
  }

  _createCircle() {
    const self = this
    const particles = []
    for (let i = 0; i < this.numParticles; i++) {
      const color = self.colors[~~(self._rand(0, self.colors.length))]
      particles[i] = {
        radius: self._rand(self.minRadius, self.maxRadius),
        xPos: self._rand(0, self.canvas.width),
        yPos: self._rand(0, self.canvas.height - self.maxRadius),
        color: 'rgb(' + color + ')'
      }
      self._draw(particles, i)
    }
  }

  _draw(particles, i) {
    const ctx = this.ctx

    ctx.fillStyle = '#fff'
    ctx.strokeStyle = particles[i].color
    ctx.lineWidth = 2
    ctx.stroke()
    ctx.beginPath()
    ctx.arc(particles[i].xPos, particles[i].yPos, particles[i].radius, 0, 2 * Math.PI, false)
    ctx.fill()
  }

  render() {
    if (this.dpr > 1) {
      this.canvas.width  = this.canvas.offsetWidth * this.dpr
      this.canvas.height = this.canvas.offsetHeight * this.dpr
      this.ctx.scale(this.dpr, this.dpr)
    } else {
      this.canvas.width  = this.canvas.offsetWidth
      this.canvas.height = this.canvas.offsetHeight
    }
    this._createCircle()
  }

}